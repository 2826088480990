import { ReactNode } from 'react';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  href: string;
  title: string;
  color: string;
  children: ReactNode;
}

export const OverviewPopularLink = ({ href, title, color, children }: IProps) => {
  const { navigateTo } = Model({ href });

  return (
    <div className={styles['link']} onClick={navigateTo}>
      <div className={styles['link__figure']} style={{ backgroundColor: color }}>
        <div className={styles['link__icon']}>{children}</div>
      </div>
      <p className={styles['link__text']}>{title}</p>
    </div>
  );
};
