import { Link } from 'react-router-dom';

import styles from './index.module.scss';

interface IProps {
  backgroundColor: string;
  logoUrl: string | null;
}

export const HeaderLogoLink = ({ backgroundColor, logoUrl }: IProps) => (
  <Link to='/' className={styles['link']} style={{ background: backgroundColor }}>
    <img
      className={styles['link__img']}
      src={logoUrl || ''}
      alt='Логотип'
      crossOrigin='anonymous'
    />
  </Link>
);
