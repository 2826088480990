import { useSelector } from 'react-redux';

import { configAppSelector } from '@shared/store/slices/configAppSlice';

import { serverUrl } from '@shared/utils/server';

export const Model = () => {
  const configApp = useSelector(configAppSelector.getConfig);

  return {
    logoUrl: configApp.logo ? serverUrl + configApp.logo.url : '',
    logoBackgroundColor: configApp.logoBackgroundColor,
  };
};
