const IconCheckNotification = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'>
    <g clipPath='url(#clip0_2443_3413)'>
      <path
        d='M9 0C4.03754 0 0 4.03719 0 9C0 13.9628 4.03754 18 9 18C13.9625 18 18 13.9628 18 9C18 4.03719 13.9625 0 9 0ZM14.0175 5.9985L8.47904 12.2293C8.34265 12.3826 8.15262 12.4615 7.96119 12.4615C7.80923 12.4615 7.65658 12.4117 7.52885 12.3099L4.06731 9.54069C3.76892 9.30219 3.72046 8.86638 3.95931 8.56765C4.19781 8.26892 4.63396 8.22046 4.93235 8.45931L7.88054 10.8177L12.9825 5.07808C13.2359 4.79215 13.6741 4.76654 13.9597 5.02062C14.2456 5.27504 14.2716 5.71258 14.0175 5.9985Z'
        fill='#55CE63'
      />
    </g>
    <defs>
      <clipPath id='clip0_2443_3413'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconCheckNotification;
