import CircularLoaderMui from '@shared/ui/circularLoaderMui';

import styles from './index.module.scss';

export const OverviewLoader = () => (
  <div className={styles['loader']}>
    <div className={styles['loader__column']}>
      <CircularLoaderMui size={40} />
    </div>
  </div>
);
