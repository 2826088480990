import { useNavigate } from 'react-router-dom';

interface IProps {
  href: string;
}

export const Model = ({ href }: IProps) => {
  const navigate = useNavigate();

  const navigateTo = () => navigate(href);

  return {
    navigateTo,
  };
};
