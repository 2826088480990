import { ReactNode } from 'react';

import HiddenContent from '@shared/ui/roleBasedHiddenContent';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  children: ReactNode;
}

export const OverviewInvoiceList = ({ children }: IProps) => {
  const { gridTemplateColumns } = Model();

  return (
    <div className={styles['list']}>
      <div className={styles['list__head']} style={{ gridTemplateColumns }}>
        <HiddenContent roleList={['renter']}>
          <p className={styles['list__title-column']}>Контрагент</p>
        </HiddenContent>
        <p className={styles['list__title-column']}>Номер</p>
        <p className={styles['list__title-column']}>Дата</p>
        <p className={styles['list__title-column']}>Сумма</p>
        <p className={styles['list__title-column']}>Статус</p>
      </div>
      <div className={styles['list__body']}>{children}</div>
    </div>
  );
};
