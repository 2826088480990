import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IModalPopup {
  isOpen: boolean;
  type: 'message' | 'confirm';
  title: string;
  message: string | { title: string; message: string }[];
  action: (() => void) | undefined;
}

interface IModalPopupOpenParams extends Omit<IModalPopup, 'isOpen' | 'title'> {
  title?: string;
}

const initialState: IModalPopup = {
  isOpen: false,
  type: 'message',
  title: '',
  message: '',
  action: undefined,
};

const ModalPopup = createSlice({
  name: 'ModalPopup',
  initialState: {
    value: initialState,
  },
  reducers: {
    openModalPopup: (state, action: PayloadAction<IModalPopupOpenParams>) => {
      const { type, title, message, action: currentAction } = action.payload;

      state.value = {
        isOpen: true,
        type,
        title: title ?? '',
        message,
        action: currentAction,
      };
    },
    closeModalPopup: (state) => {
      state.value.isOpen = false;
    },
  },
});

export const modalPopupSelector = {
  getState: (state: {
    modalPopup: {
      value: IModalPopup;
    };
  }) => state.modalPopup.value,
};

export const { openModalPopup, closeModalPopup } = ModalPopup.actions;

export default ModalPopup.reducer;
