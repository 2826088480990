import { ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TThunkDispatch } from '@shared/store';

import { userSelector } from '@shared/store/slices/userSlice';
import { addNotification, removeNotification } from '@shared/store/slices/notificationListSlice';

import { getIdFromDate } from '@shared/helpers';

import { TSocketError } from '@shared/types/global';

import socket from './socket.io';

interface IProps {
  children: ReactNode;
}

const SocketApp = ({ children }: IProps) => {
  const dispatch = useDispatch<TThunkDispatch>();

  const { isAuth, access } = useSelector(userSelector.getState);

  const reconnectLoaderIdRef = useRef<null | string>(null);

  const disconnectSocket = () => {
    if (reconnectLoaderIdRef.current) {
      return;
    }

    const loaderId = getIdFromDate();
    reconnectLoaderIdRef.current = loaderId;

    dispatch(addNotification({ id: loaderId, type: 'loader', title: 'Переподключение' }));
  };

  const reconnectSocket = () => {
    if (reconnectLoaderIdRef.current) {
      dispatch(removeNotification(reconnectLoaderIdRef.current));

      reconnectLoaderIdRef.current = null;
    }
  };

  const handleAppealSocketListeners = (listener: 'connect' | 'disconnect') => {
    if (!access['appeal']) {
      return;
    }

    if (listener === 'connect') {
      socket.on('appeal:get-error', (err: TSocketError) => {
        dispatch(
          addNotification({
            type: 'error',
            title: 'Ошибка обмена данными',
            description: err.message,
          }),
        );
      });

      return;
    }

    socket.off('appeal:get-error');
  };

  useEffect(() => {
    if (isAuth && !socket.connected) {
      socket.connect();

      socket.io.on('close', disconnectSocket);
      socket.io.on('reconnect', reconnectSocket);

      handleAppealSocketListeners('connect');
    }

    return () => {
      socket.io.removeListener('close', disconnectSocket);
      socket.io.removeListener('reconnect', reconnectSocket);

      handleAppealSocketListeners('disconnect');

      socket.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return children;
};

export default SocketApp;
