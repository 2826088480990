import { Link } from 'react-router-dom';

import HiddenContent from '@shared/ui/roleBasedHiddenContent';

import { getDate } from '@shared/helpers';

import styles from './index.module.scss';

import { Model } from '../model';

interface IProps {
  href: string;
  number: string;
  date: string;
  status: { name: string; backgroundColor: string };
  sum: number;
  contractor: string;
}

export const OverviewInvoiceLink = ({ href, number, date, status, sum, contractor }: IProps) => {
  const { currency, gridTemplateColumns } = Model({ sum });

  return (
    <Link to={href} className={styles['item']} style={{ gridTemplateColumns }}>
      <HiddenContent roleList={['renter']}>
        <p className={styles['item__text']} title={contractor}>
          {contractor}
        </p>
      </HiddenContent>
      <p className={styles['item__text']} title={number}>
        {number}
      </p>
      <p className={styles['item__text']}>{getDate(date, true)}</p>
      <p className={styles['item__text']} title={currency}>
        {currency}
      </p>
      <div className={styles['item__status']} style={{ backgroundColor: status.backgroundColor }}>
        <p className={styles['item__status-text']}>{status.name}</p>
      </div>
    </Link>
  );
};
