import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useRef } from 'react';

import { modalPopupSelector, closeModalPopup } from '@shared/store/slices/modalPopupSlice';

export const Model = () => {
  const dispatch = useDispatch();

  const messageRef = useRef<HTMLDivElement | null>(null);

  const { isOpen, type, title, message, action } = useSelector(modalPopupSelector.getState);

  const onClick = () => {
    if (action) {
      action();
    }

    dispatch(closeModalPopup());
  };

  const onClose = () => {
    dispatch(closeModalPopup());
  };

  const handleClickOutSide = useCallback((evt: MouseEvent) => {
    if (!messageRef.current?.contains(evt.target as Node)) {
      onClose();
    }
  }, []);

  return {
    messageRef,
    isOpen,
    type,
    title,
    message,
    onClick,
    onClose,
    handleClickOutSide,
  };
};
