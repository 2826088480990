import { Transition } from 'react-transition-group';
import { useRef } from 'react';

import IconCheckNotification from '@shared/assets/icons/icon-check-notification';
import IconCrossNotification from '@shared/assets/icons/icon-cross-notification';
import IconClose from '@shared/assets/icons/icon-close';

import CircularLoaderMui from '@shared/ui/circularLoaderMui';

import { INotificationListItem } from '@shared/types/interfaces/notification.interface';

import styles from './index.module.scss';

import { Model } from '../model';

const iconList = {
  success: <IconCheckNotification />,
  error: <IconCrossNotification />,
  loader: <CircularLoaderMui size={18} />,
};

interface IProps extends INotificationListItem {}

export const NotificationItem = ({
  id,
  type,
  title,
  description,
  link,
  ...propsTranistion
}: IProps) => {
  const { isVisible, navigateTo, onClose } = Model({ id, type, link });

  const notificationRef = useRef<HTMLDivElement | null>(null);

  return isVisible ? (
    <Transition
      {...propsTranistion}
      nodeRef={notificationRef}
      timeout={200}
      appear
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <div
          ref={notificationRef}
          className={`${styles['item']} ${!!link && styles['item_type_link']} ${
            styles[`item-${state}`]
          }`}
          onClick={link ? navigateTo : undefined}
        >
          <div className={styles['item__container']}>
            <div className={styles['item__icon']}>{iconList[type]}</div>
            <div className={styles['item__body']}>
              <p className={styles['item__title']}>{title}</p>
              {description && <p className={styles['item__description']}>{description}</p>}
            </div>
          </div>
          {type !== 'loader' ? (
            <button
              type='button'
              className={`${styles['item__btn-close']} ${
                description && styles['item__btn-close_position_top']
              }`}
              onClick={onClose}
            >
              <IconClose />
            </button>
          ) : null}
        </div>
      )}
    </Transition>
  ) : null;
};
