import Menu from '@entities/sidebar/menu';
import RoleLink from '@entities/sidebar/roleLink';
import Navigation from '@features/navigation';
import AppealLink from '@features/navigation-link-appeal';
import ClaimLink from '@features/navigation-link-claim';

import { Model } from '../model';

export const Sidebar = () => {
  const { isAuth } = Model();

  return (
    <Menu>
      {isAuth && (
        <>
          <RoleLink />
          <Navigation AppealLink={AppealLink} ClaimLink={ClaimLink} />
        </>
      )}
    </Menu>
  );
};
