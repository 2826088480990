import { MutableRefObject } from 'react';
import { Transition } from 'react-transition-group';

import Button from '@shared/ui/button';
import IconWarning from '@shared/assets/icons/icon-warning';

import colors from '@shared/assets/colors';

import styles from './index.module.scss';

interface IProps {
  messageRef: MutableRefObject<HTMLDivElement | null>;
  isOpen: boolean;
  type: 'message' | 'confirm';
  message: string | { title: string; message: string }[];
  title?: string;
  onClick: () => void;
  onClose: () => void;
}

export const ModalMessage = ({
  messageRef,
  isOpen,
  type,
  title,
  message,
  onClick,
  onClose,
}: IProps) => (
  <Transition nodeRef={messageRef} in={isOpen} timeout={400} mountOnEnter unmountOnExit>
    {(state) => (
      <div ref={messageRef} className={`${styles['message']} ${styles[`message-${state}`]}`}>
        <div className={styles['message__header']}>
          <div className={styles['message__icon']}>
            <IconWarning />
          </div>
          <p className={styles['message__title']}>{title || 'Внимание'}</p>
        </div>
        <div className={styles['message__body']}>
          {typeof message === 'string' && message ? (
            <p className={styles['message__text']}>{message}</p>
          ) : null}
          {Array.isArray(message) && message.length
            ? message.map((item) => (
                <>
                  <p
                    className={`${styles['message__text']} ${styles['message__text_weight_bold']}`}
                  >
                    {item.title}
                  </p>
                  <p className={styles['message__text']}>{item.message}</p>
                </>
              ))
            : null}
          <div className={styles['message__btns']}>
            {type === 'confirm' ? (
              <>
                <Button
                  color={colors.green}
                  text='Продолжить'
                  type='button'
                  onClick={onClick}
                  minWidth={190}
                />
                <Button
                  color={colors.gray12}
                  text='Отмена'
                  type='button'
                  onClick={onClose}
                  minWidth={190}
                />
              </>
            ) : (
              <Button
                color={colors.green}
                text='Отлично'
                type='button'
                isContentCenter
                onClick={onClose}
              />
            )}
          </div>
        </div>
      </div>
    )}
  </Transition>
);
