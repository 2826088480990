import { useSelector } from 'react-redux';

import { userSelector } from '@shared/store/slices/userSlice';

export const Model = () => {
  const contractor = useSelector(userSelector.getContractor);

  const contractorParam = contractor?._id ? `contractor=${contractor._id}` : '';

  const urlList = {
    placementListUrl: `/placement-list?${contractorParam}&isFree=true&page=1&count=20`,
    appealAnsweredListUrl: `/appeal-list?${contractorParam}&status=2&page=1&count=20`,
    appealUnansweredListUrl: `/appeal-list?${contractorParam}&status=1&page=1&count=20`,
    invoiceListUrl: `/invoice-list?${contractorParam}&status=1&page=1&count=20`,
  };

  return {
    urlList,
  };
};
