import Section from '@entities/overview/section';
import Container from '@entities/overview/popularContainer';
import Link from '@entities/overview/popularLink';
import HiddenContent from '@shared/ui/roleBasedHiddenContent';

import IconHouse from '@shared/assets/icons/icon-house';
import IconGuestPass from '@shared/assets/icons/icon-guest-pass';
import IconReceipt from '@shared/assets/icons/icon-receipt';

import colors from '@shared/assets/colors';

import { Model } from '../model';

export const OverviewPopular = () => {
  const { urlList } = Model();

  return (
    <Section title='Популярное'>
      <Container>
        <HiddenContent roleList={['renter']}>
          <Link href={urlList.placementListUrl} title='Cвободные помещения' color={colors.green}>
            <IconHouse />
          </Link>
        </HiddenContent>
        <HiddenContent roleList={['director', 'employee']}>
          <Link
            href={urlList.appealAnsweredListUrl}
            title='Отвеченные обращения'
            color={colors.purple}
          >
            <IconGuestPass />
          </Link>
        </HiddenContent>
        <HiddenContent roleList={['renter']}>
          <Link
            href={urlList.appealUnansweredListUrl}
            title='Неотвеченные обращения'
            color={colors.purple}
          >
            <IconGuestPass />
          </Link>
        </HiddenContent>
        <Link href={urlList.invoiceListUrl} title='Неоплаченные счёта' color={colors.red2}>
          <IconReceipt />
        </Link>
      </Container>
    </Section>
  );
};
