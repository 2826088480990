type TGetError = (err: any | unknown) => string;

export const getError: TGetError = (err) => {
  if (typeof err.message === 'string') {
    return err.message;
  }

  console.log(err);

  return 'Неизвестная ошибка.';
};
