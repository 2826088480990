import Section from '@entities/overview/section';
import List from '@entities/overview/invoiceList';
import Link from '@entities/overview/invoiceListLink';

import DefaultMessage from '@entities/overview/defaultMessage';
import Loader from '@entities/overview/loader';

import { Model } from '../model';

export const OverviewInvoice = () => {
  const { status, statusText, invoiceList, getStatus } = Model();

  const getContent = () => {
    if (status === undefined) {
      return <Loader />;
    }

    if (status === 200) {
      return invoiceList?.length ? (
        invoiceList.map((item) => (
          <Link
            key={item._id}
            href={`/invoice/${item._id}`}
            number={item.number}
            date={item.date}
            status={getStatus(item.status)}
            sum={item.sum}
            contractor={item.contractor?.name || '-'}
          />
        ))
      ) : (
        <DefaultMessage message='Список счетов пуст' />
      );
    }

    return <DefaultMessage message={statusText} />;
  };

  return (
    <Section title='Последние счета'>
      <List>{getContent()}</List>
    </Section>
  );
};
