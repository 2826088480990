import Popup from '@shared/ui/popup';
import ModalMessage from '@entities/modalMessage';

import { Model } from '../model';

export const ModalPopup = () => {
  const { messageRef, isOpen, type, title, message, onClick, onClose, handleClickOutSide } =
    Model();

  return (
    <Popup isOpen={isOpen} clickOutSide={handleClickOutSide}>
      <ModalMessage
        messageRef={messageRef}
        isOpen={isOpen}
        type={type}
        title={title}
        message={message}
        onClick={onClick}
        onClose={onClose}
      />
    </Popup>
  );
};
