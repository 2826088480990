import { useSelector } from 'react-redux';

import { userSelector } from '@shared/store/slices/userSlice';
import { configAppSelector } from '@shared/store/slices/configAppSlice';

interface IProps {
  changeIsOpen: () => void;
}

export const Model = ({ changeIsOpen }: IProps) => {
  const { user } = useSelector(userSelector.getState);
  const configApp = useSelector(configAppSelector.getConfig);

  const name = user.name.split(' ').slice(0, 2).join(' ');

  const onClose = () => {
    changeIsOpen();
  };

  return {
    name,
    contractorHref: `/contractor/${user.contractor?._id}`,
    position: user.position,
    iconColor: configApp.themeColor,
    isShowContractorBtn: user.role.code === 'renter',
    onClose,
  };
};
