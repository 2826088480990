import axios from 'axios';

import { TApiError } from '@shared/types/global';

type ThandleApiError = (err: unknown) => TApiError;

export const handleApiError: ThandleApiError = (err) => {
  if (axios.isAxiosError(err) && err.response) {
    if (err.code === 'ECONNABORTED') {
      return {
        status: 408,
        message: 'Время ожидания превышено.',
      };
    }

    const status = err.response.request.status ? err.response.request.status : 502;
    const message = err.response.data.message ? err.response.data.message : 'Ошибка неизвестна.';

    return {
      status,
      message,
    };
  }

  return {
    status: 408,
    message: 'Время ожидания превышено.',
  };
};
