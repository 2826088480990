import { RefObject } from 'react';
import { useHover } from '@shared/hooks/useHover';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

import { Model } from '../model';

export const SidebarRoleLink = () => {
  const { role, color, colorHover } = Model();

  const [btnRef, isHovered] = useHover();

  const getStyle = () =>
    isHovered
      ? {
          color: colorHover,
        }
      : {
          color,
        };

  return (
    <Link
      to='/profile'
      ref={btnRef as RefObject<HTMLAnchorElement>}
      className={styles['link']}
      style={getStyle()}
    >
      {role}
    </Link>
  );
};
