const IconCrossNotification = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'>
    <g clipPath='url(#clip0_2454_3448)'>
      <path
        d='M15.367 2.63307C11.8564 -0.877516 6.14399 -0.877862 2.63307 2.63307C-0.877862 6.14399 -0.877516 11.8561 2.63307 15.367C6.14365 18.8779 11.8561 18.8779 15.367 15.367C18.8776 11.8561 18.8776 6.14365 15.367 2.63307ZM12.5508 12.5508C12.2803 12.8213 11.8419 12.8213 11.5714 12.5508L9.00004 9.97942L6.30641 12.673C6.03594 12.9435 5.59751 12.9435 5.32704 12.673C5.05657 12.4026 5.05657 11.9641 5.32704 11.6937L8.02067 9.00004L5.44929 6.42866C5.17882 6.15819 5.17882 5.71941 5.44929 5.44929C5.71976 5.17882 6.15819 5.17882 6.42866 5.44929L9.00004 8.02067L11.4488 5.57188C11.7193 5.30141 12.1577 5.30141 12.4282 5.57188C12.6987 5.84236 12.6987 6.28079 12.4282 6.55126L9.97942 9.00004L12.5508 11.5714C12.8213 11.8419 12.8213 12.2803 12.5508 12.5508Z'
        fill='#FF0000'
      />
    </g>
    <defs>
      <clipPath id='clip0_2454_3448'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconCrossNotification;
