import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import server from '@shared/utils/server';

import { handleApiError } from '@shared/helpers';

import { IUserProfile, IUserProfileForm } from '@shared/types/interfaces/user.interface';
import { IAuthForm } from '@shared/types/interfaces/auth.interface';
import { TApiError } from '@shared/types/global';

interface IUser {
  isAuth: boolean | undefined;
  user: IUserProfile;
  access: Record<string, boolean>;
  isLoading: boolean;
  error: null | TApiError;
}

const initialUser: IUserProfile = {
  _id: '',
  name: '',
  position: '',
  isActive: false,
  role: {
    _id: '',
    name: '',
    code: '',
    resources: [],
  },
  email: '',
  emailNotifications: false,
  phone: '',
  contractor: null,
  login: '',
  createdAt: '',
  updatedAt: '',
};

const initialState: IUser = {
  isAuth: undefined,
  user: initialUser,
  access: {},
  isLoading: false,
  error: null,
};

export const checkToken = createAsyncThunk<
  AxiosResponse<IUserProfile>,
  void,
  { rejectValue: TApiError }
>('user/checkToken', async (data, { rejectWithValue }) => {
  try {
    const res = await server.get<IUserProfile>('/user/me');
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

export const login = createAsyncThunk<
  AxiosResponse<IUserProfile>,
  IAuthForm,
  { rejectValue: TApiError }
>('user/signin', async (data, { rejectWithValue }) => {
  try {
    const res = await server.post<IUserProfile>('/signin', data);
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

export const logout = createAsyncThunk<AxiosResponse<void>, void, { rejectValue: TApiError }>(
  'user/logout',
  async (data, { rejectWithValue }) => {
    try {
      const res = await server.post<void>('/user/logout');
      return res;
    } catch (err) {
      return rejectWithValue(handleApiError(err));
    }
  },
);

export const updateProfile = createAsyncThunk<
  AxiosResponse<IUserProfile>,
  IUserProfileForm,
  { rejectValue: TApiError }
>('user/updateProfile', async (data, { rejectWithValue }) => {
  try {
    const res = await server.patch('/user/profile', data);
    return res;
  } catch (err) {
    return rejectWithValue(handleApiError(err));
  }
});

const userAdapter = createEntityAdapter();

const userSlice = createSlice({
  name: 'user',
  initialState: userAdapter.getInitialState<IUser>(initialState),
  reducers: {
    changeUser: (state, action: PayloadAction<IUserProfile>) => {
      state.user = action.payload;
    },
    changeUserAccess: (state, action: PayloadAction<Record<string, boolean>>) => {
      state.access = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkToken.pending, (state) => {
        state.isLoading = true;
        state.isAuth = undefined;
        state.error = null;
      })
      .addCase(checkToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.data;
        state.isAuth = true;
      })
      .addCase(checkToken.rejected, (state) => {
        state.isLoading = false;
        state.isAuth = false;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isAuth = false;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.data;
        state.isAuth = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload ? action.payload : null;
        state.isLoading = false;
        state.isAuth = false;
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.user = initialUser;
        state.isAuth = false;
        state.access = {};
      })
      .addCase(logout.rejected, (state, action) => {
        state.error = action.payload ? action.payload : null;
        state.isLoading = false;
      })
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.data;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.error = action.payload ? action.payload : null;
        state.isLoading = false;
      });
  },
});

export const userSelector = {
  getState: (state: { user: IUser }) => state.user,
  getResources: (state: { user: IUser }) => state.user.user.role.resources,
  getAccess: (state: { user: IUser }) => state.user.access,
  getRoleCode: (state: { user: IUser }) => state.user.user.role.code,
  getContractor: (state: { user: IUser }) => state.user.user.contractor,
};

export const { changeUser, changeUserAccess } = userSlice.actions;

export default userSlice.reducer;
